// Generated by Framer (0f540f8)

import { addFonts, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Z0JkIIzaF"];

const serializationHash = "framer-buBqD"

const variantClassNames = {Z0JkIIzaF: "framer-v-ault75"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Z0JkIIzaF", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.nav {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ault75", className, classNames)} data-border data-framer-name={"Transparente"} data-hide-scrollbars layoutDependency={layoutDependency} layoutId={"Z0JkIIzaF"} ref={ref ?? ref1} style={{"--border-bottom-width": "0.5px", "--border-color": "rgba(255, 255, 255, 0.32)", "--border-left-width": "0px", "--border-right-width": "0px", "--border-style": "solid", "--border-top-width": "0px", ...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-buBqD.framer-ovonsx, .framer-buBqD .framer-ovonsx { display: block; }", ".framer-buBqD.framer-ault75 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: 64px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 1100px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-buBqD.framer-ault75 { gap: 0px; } .framer-buBqD.framer-ault75 > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-buBqD.framer-ault75 > :first-child { margin-top: 0px; } .framer-buBqD.framer-ault75 > :last-child { margin-bottom: 0px; } }", ".framer-buBqD[data-border=\"true\"]::after, .framer-buBqD [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", ".framer-buBqD[data-hide-scrollbars=\"true\"]::-webkit-scrollbar, .framer-buBqD [data-hide-scrollbars=\"true\"]::-webkit-scrollbar { width: 0px; height: 0px; }", ".framer-buBqD[data-hide-scrollbars=\"true\"]::-webkit-scrollbar-thumb, .framer-buBqD [data-hide-scrollbars=\"true\"]::-webkit-scrollbar-thumb { background: transparent; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 64
 * @framerIntrinsicWidth 1100
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerRtRCJ91pf: React.ComponentType<Props> = withCSS(Component, css, "framer-buBqD") as typeof Component;
export default FramerRtRCJ91pf;

FramerRtRCJ91pf.displayName = "NavigationEnglish";

FramerRtRCJ91pf.defaultProps = {height: 64, width: 1100};

addFonts(FramerRtRCJ91pf, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})